import React from 'react';
import { useSelector } from 'react-redux';

// import { ReactComponent as LogoDarkIcon } from '../../assets/images/logos/dark-logo-icon.svg';
// import { ReactComponent as LogoDarkText } from '../../assets/images/logos/dark-logo-text.svg';
// import { ReactComponent as LogoWhiteIcon } from '../../assets/images/logos/white-logo-icon.svg';
// import { ReactComponent as LogoWhiteText } from '../../assets/images/logos/white-logo-text.svg';
import Logo from '../../assets/images/logos/qr-code-logo.png';

const AuthLogo = () => {
  const isDarkMode = useSelector((state) => state.customizer.isDark);

  return (
    <div className="py-4 d-flex justify-content-center align-items-center gap-2">
      {isDarkMode !== false ? (
        <>
          <img src={Logo} alt="logo" width="40" height="40" />
          {/* <LogoWhiteIcon />
          <LogoWhiteText /> */}
        </>
      ) : (
        <>
          <img src={Logo} alt="logo" width="75" height="75" />
          <div className="d-flex flex-column pt-2">
            <h2 style={{ fontWeight: 'bold', fontSize: '32px', color: '#0C69C7' }}>PEMA </h2>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: '-10px', color: '#0C69C7' }}
            >
              <small className="fw-bold" style={{ color: '#0C69C7' }}>
                Integrated Vendor Database System
              </small>
            </div>
          </div>
          {/* <LogoDarkIcon /> */}
          {/* <LogoDarkText /> */}
        </>
      )}
    </div>
  );
};

export default AuthLogo;
