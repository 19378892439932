import { lazy } from 'react';
import Loadable from '../layouts/loader/Loadable';
import RequireAuth from '../components/RequireAuth';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import NewPassword from '../pages/auth/NewPassword';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Pages ****/
const Unauthorized = Loadable(lazy(() => import('../pages/auth/Unauthorized')));
const LandingPage = Loadable(lazy(() => import('../pages/landing/Landing')));
const VerifiedStatus = Loadable(lazy(() => import('../pages/spda/VerifiedStatus')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const Registration = Loadable(lazy(() => import('../pages/registration/Registration')));
const TenderDetail = Loadable(lazy(() => import('../pages/tender/Detail')));
const TenderRegistration = Loadable(lazy(() => import('../pages/tender/UploadDocument')));
const Spda = Loadable(lazy(() => import('../pages/spda/Spda')));
const DocumentPreview = Loadable(lazy(() => import('../pages/DocumentPreview')));
const ChangePassword = Loadable(lazy(() => import('../pages/auth/ChangePassword')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));

const ROLES = {
  Vendor: 'Vendor',
};

/*****Routes******/
const ThemeRoutes = [
  // PUBLIC ROUTE
  {
    path: '',
    element: <LandingPage />,
    name: 'Landing Page',
  },
  {
    path: 'spda-status',
    element: <VerifiedStatus />,
    name: 'Landing Page',
  },
  // PUBLIC ROUTE

  // PROTECTED ROUTES
  {
    path: '/',
    element: <RequireAuth allowedRoles={[ROLES.Vendor]} />,
    children: [
      {
        path: '/',
        element: <FullLayout />,
        children: [
          {
            path: 'dashboard',
            name: 'Dashboard',
            element: <Dashboard />,
          },
          {
            path: 'registration',
            name: 'Company Registration',
            element: <Registration />,
          },
          {
            path: 'tender/:slug',
            name: 'Tender Detail',
            element: <TenderDetail />,
          },
          {
            path: 'tender/:slug/upload-document',
            name: 'Tender Registration',
            element: <TenderRegistration />,
          },
          {
            path: '/spda',
            name: 'Download SPDA',
            element: <Spda />,
          },
          {
            path: '/change-password',
            name: 'Ganti Password',
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: 'preview',
        name: 'Document Preview',
        element: <DocumentPreview />,
      },
    ],
  },
  // PROTECTED ROUTES

  // LOGIN ROUTE
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      {
        path: 'login',
        name: 'Login',
        element: <Login />,
      },
      {
        path: 'register',
        name: 'Register',
        element: <Register />,
      },
      {
        path: 'forgot-password',
        name: 'Forgot Password',
        element: <ForgotPassword />,
      },
      {
        path: 'new-password',
        name: 'New Password',
        element: <NewPassword />,
      },
    ],
  },
  // LOGIN ROUTE

  // Unauthorized
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      {
        path: 'unauthorized',
        name: 'Unauthorized',
        element: <Unauthorized />,
      },
    ],
  },
  // Unauthorized
];

export default ThemeRoutes;
